import Image from "../widgets/Image";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/views/about.scss";

About.propTypes = {
  page: PropTypes.object,
};

const cl = _classes(styles);

export default function About({ page }) {

  const renderAboutBlock = () => {

    const aboutBlock = page.fieldgroup1[0];

    return (
      <div className={cl("about_container")}>
        <div className={cl("image_container")}>
          <Image
            className={cl("image")}
            src={aboutBlock.image1} 
            alt={aboutBlock.image1_alt_text} 
            />
        </div>
        <div className={cl("content_container")}>
          <div className={cl("content_table_cell")}>
            <h1 className={cl("title_container")}>
              <div className={cl("title")}>
                {aboutBlock.h1}
              </div>
              <div className={cl("title_colored")}>
                {aboutBlock.h2}
              </div>
              <div className={cl("title")}>
                {aboutBlock.h3}
              </div>
            </h1>
            <div 
              className={cl("paragraph")}
              dangerouslySetInnerHTML={{ __html: aboutBlock.blurb1 }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>{renderAboutBlock()}</div>
  )
}

