import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import ReactDayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import { useFormContext } from "./FormProvider";
import { calendarArrow } from "../widgets/SVG";
import { v4 as uuidv4 } from "uuid";

const UUID = uuidv4();

DatePickerInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  dayPickerProps: PropTypes.object,
  fromMonth: PropTypes.object,
  format: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};

DatePickerInput.defaultProps = {
  name: "date",
  format: "LL",
  dayPickerProps: {},
  className: "",
};

export default function DatePickerInput({
  name,
  placeholder,
  label,
  rules,
  dayPickerProps,
  fromMonth,
  className,
  defaultValue,
  format,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  const internalFormat = "MM/DD/YYYY";

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <div className={"field field-datepicker " + className}>
          <Label value={label} htmlFor={id} />
          <div className={"control"}>
            <ReactDayPickerInput
              className="date_picker"
              inputProps={{ id }}
              numberOfMonths={1}
              fromMonth={fromMonth}
              value={
                value ? moment(value, internalFormat).format(format) : undefined
              }
              readOnly={true}
              dayPickerProps={{
                navbarElement: NavbarElement,
                onDayClick: (date) =>
                  onChange(moment(date).format(internalFormat)),
                disabledDays: {
                  before: moment().toDate(),
                },
                highlighted: value,
                ...dayPickerProps,
              }}
              placeholder={placeholder}
              formatDate={formatDate}
              parseDate={parseDate}
              format={format}
            />
          </div>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}

NavbarElement.propTypes = {
  month: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

function NavbarElement({ month, onNextClick, onPreviousClick }) {
  return (
    <div className={"DayPicker-Caption-Custom"}>
      <button
        type="button"
        onClick={() => onPreviousClick(null)}
        className={"DayPicker-Arrow previous"}
      >
        {calendarArrow}
      </button>
      <span>{moment(month).format("MMMM YYYY")}</span>
      <button
        type="button"
        onClick={() => onNextClick(null)}
        className={"DayPicker-Arrow next"}
      >
        {calendarArrow}
      </button>
    </div>
  );
}
