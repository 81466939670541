import Carousel from "../widgets/Carousel";
import { _classes } from "../utilities/helpers";
import { useViewportContext } from "../providers";
import styles from "../../styles/views/gallery.scss";

const cl = _classes(styles);

const Gallery = ({ page }) => {
  const slides = page.fieldgroup1;

  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");
  const sliderSettings = () => {
    if (tablet) {
      return {
        arrows: false,
        centerMode: false,
        className: "gallery-carousel center",
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,
      };
    }

    return {
      arrows: false,
      className: "gallery-carousel center",
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      centerMode: true,
      slidesToShow: 1,
      variableWidth: true,
    };
  };

  return (
    <div className={cl("gallery")}>
      <h2 className={cl("title")}>{page.h1}</h2>
      <div className={cl("gallery-carousel-wrapper")}>
        <Carousel slides={slides} settings={sliderSettings()} darkArrows />
      </div>
    </div>
  );
};

export default Gallery;
