import styles from "../../styles/views/home.scss";
// import Footer from "../partials/Footer";
import Carousel from "../widgets/Carousel";
import About from "./about1.js";
import Restaurants from "./restaurants.js";
import Careers from "./careers.js";
import Gallery from "./gallery.js";
import Contact from "./contact.js";
import Team from "./team.js";
import SpecialEvents from "./SpecialEvents";
import useAppState from "../providers/AppProvider";
import { _classes } from "../utilities/helpers";
import ScrollContainer from "../containers/ScrollContainer";
import { useEffect, useState } from "react";
const cl = _classes(styles);

const TEMPLATES = {
  about1: About,
  restaurants: Restaurants,
  special_events: SpecialEvents,
  careers: Careers,
  contact: Contact,
  gallery: Gallery,
  team: Team,
};

const Home = (props) => {
  const { childrenById, scrollLocation, setLocation, byPath, byTemplate } =
    useAppState();

  let _w_path = window.location.pathname;
  const _init_menuPath = _w_path;
  const [menupath, setMenuPath] = useState(_init_menuPath);
  const currentPage = byPath(window.location.pathname);
  const currentmenu = currentPage;
  const currentPath = window.location.pathname;
  const homePage = byTemplate("home")[0];
  const allpages = childrenById(1).filter((i) => i.showinnav);

  console.info("# homePage ", homePage);
  const slides = homePage.fieldgroup1;

  const sliderSettings = () => {
    return {
      arrows: false,
      fade: true,
      centerMode: false,
      className: "home-hero-carousel center",
      infinite: true,
      autoplay: true,
      speed: 3000,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: false,
    };
  };

  const sharedprops = {
    history: props.history,
    setLocation: setLocation,
    scrollLocation: scrollLocation,
    resetScrollLocation: () => {},
  };

  const renderSection = () => {
    return allpages.map((page) => {
      const Component = TEMPLATES[page.template];
      if (Component) {
        return (
          <ScrollContainer
            key={page.id}
            {...sharedprops}
            path={page.path}
            page={page}
          >
            <Component page={page} />
          </ScrollContainer>
        );
      }
    });
  };

  return (
    <div className={cl("_")}>
      {/* <ScrollContainer {...sharedprops} path="/" section="home"> */}
      <div className={cl("home-carousel-wrapper")}>
        <Carousel
          slides={slides}
          settings={sliderSettings()}
          heroBannerCarousel
        />
      </div>
      {/* </ScrollContainer> */}

      {renderSection()}
    </div>
  );
};

export default Home;
