import { React, useEffect, useState } from "react";
import { motion } from "../utilities/FramerMotion";
import Link from "../widgets/Link";
import { _classes, parse } from "../utilities/helpers";
import styles from "../../styles/partials/mobile-nav.scss";
import useAppState from "../providers/AppProvider";
import AnimateHeight from "react-animate-height";

const cl = _classes(styles);

const variantsnav = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 0 },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 80,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

MobileNav.propTypes = {};

export default function MobileNav({ opennav, setOpenNav, setLocation }) {
  const {
    childrenById,
  } = useAppState();
  const [openSubNav, setOpenSubNav] = useState(false);
  
  const items = childrenById(1).filter((item) => item.showinnav === true);

  const renderSubnav = (page) => {
    const children = page.fieldgroup1;
    if (
      children &&
      children.length > 0
      // !APP_CONFIG.noSubnav.includes(template)
    ) {
      return (
        <div className={cl(["subnav", openSubNav && "active"])}>
          {children.map((item) => {
            const linkObj = parse(item.buttonlink1);
            return (
              <Link
                className={cl("subnav__item")}
                key={item.id}
                path={linkObj.link}
                onClick={() => {
                  ga("send", {
                    hitType: "Event",
                    eventCategory: "Click",
                    eventAction: "Completion",
                    eventLabel: "Booking",
                    eventValue: 0,
                  });
                }}  
              >
                <div
                  className={cl("subnav__itemTitle")}
                >
                  {linkObj.text}
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
  };

  return (
    <motion.div
      animate={opennav ? "open" : "closed"}
      variants={variantsnav}
      className={cl(["_"])}
    >
      <div className={cl(["modal_wrapper", "nav_wrapper"])}>
        <div className={cl("modal_container")}>
          <motion.ul
            className={cl(["items"])}
            variants={variantsul}
          >
            {items &&
              items.map((item) => {
                const _template = item.template;
                const _pathname =
                  _template === "link_out" ? item.h6 : item.path;
                const active = window.location.pathname.includes(_pathname);
                if (_template === "gallery") return null;
                return (
                  <AnimateHeight
                    height={"auto"}
                    duration={400}
                  >
                    <li
                      key={item.id}
                      className={cl(["item"])}
                      variants={variants_li}
                    >
                      <Link
                        className={cl([
                          "mobile_link",
                          active && "nav_active",
                      ,
                        ])}
                        path={item.path}
                        onClick={() => {
                          if (item.template === "reservations") setOpenSubNav(!openSubNav);
                          else setLocation(item.path), setOpenNav(false), window.history.replaceState( {} , "", item.path );
                        }}
                      >
                        {item.linktitle}
                      </Link>
                      {item.template === "reservations" && openSubNav && renderSubnav(item)}
                    </li>
                  </AnimateHeight>
                );
              })
            }
          </motion.ul>
        </div>
      </div>
    </motion.div>
  );
}
