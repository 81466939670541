import React from "react";
import Image from "../widgets/Image";
import CTA from "../widgets/CTA";
import { parse, _classes } from "../utilities/helpers";
import styles from "../../styles/views/careers.scss";

Careers.propTypes = {
  page: PropTypes.object,
};
const cl = _classes(styles);

export default function Careers({ page }) {
  const renderCareersBlock = () => {
    const careersBlock = page.fieldgroup1[0];
    return (
      <div className={cl("container")}>
        <div className={cl("image_container")}>
          <Image
            className={cl("image")}
            src={careersBlock.image1}
            alt={careersBlock.image1_alt_text}
          />
        </div>

        <div className={cl("content_container")}>
          <div className={cl("content_table_cell")}>
            <h2 className={cl("title_container")}>
              <div className={cl("content_title_colored")}>{careersBlock.h1}</div>
              <div className={cl("content_title")}>{careersBlock.h2}</div>
            </h2>
            <div
              className={cl("content_paragraph")}
              dangerouslySetInnerHTML={{ __html: careersBlock.blurb1 }}
            />
            <CTA
              className={cl("button")}
              text={parse(careersBlock.buttonlink1).text}
              link={parse(careersBlock.buttonlink1).link}
            />
          </div>
        </div>
      </div>
    );
  };
  return <div>{renderCareersBlock()}</div>;
}
