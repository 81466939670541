import styles from "../../styles/partials/_team-popup.scss";
import { useAppState } from "../providers";
import { _classes } from "../utilities/helpers";

TeamPopup.propTypes = {
  fieldgroup1index: PropTypes.number,
};

export default function TeamPopup({ close, fieldgroup1index }) {
  const cl = _classes(styles);
  const { byTemplate } = useAppState();
  const page = byTemplate("team")[0].fieldgroup1[fieldgroup1index];
  return (
    <div className={cl(["popup_container"])}>
      <button onClick={() => close()} className={cl("button")}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          className={cl(["svg"])}
        >
          <path d="m.35.35 21 21M.35 21.35l21-21" />
        </svg>
      </button>

      <div className={cl("content")}>
        <div className={cl("wrapper")}>
          <div>
            <h2 className={cl("title")}>
              <div className={cl("title_grey")}>{page.h2}</div>
            </h2>
            <div className={cl("position")}>{page.h3}</div>
            <div
              dangerouslySetInnerHTML={{ __html: page.blurb1 }}
              className={cl("paragraph")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
