import styles from "../../styles/partials/_popup.scss";
import { useAppState } from "../providers";
import { _classes } from "../utilities/helpers";
import {
  StandardInput,
  TextareaInput,
  SubmitInput,
  FormProvider,
  patterns,
  middleware,
  DayPickerInput,
} from "../form";

const PopUp = ({ close }) => {
  
  const cl = _classes(styles);
  const { byTemplate } = useAppState();
  const page = byTemplate("special_events")[0];

  const renderForm = () => {
    return (
      <div className={cl("form_container")}>
        <FormProvider
          onSubmit={"rfp"}
          googleAnalytics={{
            gaLabel: "RFP",
          }}
        >
          <div className={cl("block1_wrapper")}>
            <StandardInput
              name="first_name"
              label=""
              placeholder="FIRST NAME"
              rules={{ required: "First name is required" }}
            />

            <StandardInput
              name="last_name"
              label=""
              placeholder="LAST NAME"
              rules={{ required: "Last name is required" }}
            />
          </div>
          <div className={cl("block2_wrapper")}>
            <StandardInput
              name="email"
              label=""
              placeholder="EMAIL"
              rules={{ required: true, pattern: patterns.email }}
            />

            <StandardInput
              name="phone"
              label=""
              placeholder="PHONE"
              middleware={middleware.phone}
              rules={{ required: "Phone is required" }}
            />
          </div>
          <div className={cl("block3_wrapper")}>
            <DayPickerInput
              name="event_start_date"
              label=""
              placeholder="DATE OF EVENT"
              rules={{ required: true }}
            />

            <StandardInput
              name="time"
              label=""
              placeholder="DESIRED TIME OF EVENT"
              rules={{ required: "Time is required" }}
            />
          </div>
          <div className={cl("block4_wrapper")}>
            <StandardInput
              name="num_attendees"
              label=""
              placeholder="PARTY SIZE"
              rules={{ required: "Party size is required" }}
            />

            <StandardInput
              name="event_type"
              label=""
              placeholder="PARTY TYPE"
              rules={{ required: "Party type is required" }}
            />
          </div>
          <div>
            <TextareaInput
              name="comments"
              label=""
              placeholder="MESSAGE"
              rules={{ required: "Textarea is required" }}
            />
          </div>
          <SubmitInput />
        </FormProvider>
      </div>
    )
  }


    return (
      <div className={cl(["popup_container"])}>
        <button 
          onClick={() => close()}
          className={cl("button")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            className={cl(["svg"])}
          ><path d="m.35.35 21 21M.35 21.35l21-21" /></svg>
        </button>

        <div className={cl("content")}>

          <div className={cl("leftside-wrapper")}>
            {page && (
              <h2 className={cl("title")}>
                <div className={cl("title_grey")}>{page.h1}</div>
                <div className={cl("title_color")}>{page.h2}</div>
              </h2>
            )}

            {page && (
              <div 
                dangerouslySetInnerHTML={{ __html: page.blurb1 }}
                className={cl("paragraph")}
              />

            )}

            <div className={cl("sub")}>
              {page && (
                <div className="popup--blurb">
                  {page.h3}
                </div>
              )}
            </div> 
                        
          </div> 

          <div className={cl("rightside-wrapper")}>
            {renderForm()}
          </div>

        </div> 
      </div> 
    );
  } 

export default PopUp;
