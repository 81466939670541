import Link from "../widgets/Link";
import styles from "../../styles/partials/nav.scss";
import { _classes, parse } from "../utilities/helpers";
import Image from "../widgets/Image";
import { useState, useEffect } from "react";
import { useAppState, useViewportContext } from "../providers";
import MobileNav from "./MobileNav";
import { isIOS } from "../utilities/helpers";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
const cl = _classes(styles);

Nav.propTypes = {};

function Nav() {
  const { childrenById, byTemplate, setLocation } = useAppState();
  const [openSubNav, setOpenSubNav] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");

  useEffect(() => {
    if (isIOS()) {
      document.body.style.overflow = openNav ? "hidden" : "auto";
    } else {
      openNav
        ? disableBodyScroll(document.body)
        : enableBodyScroll(document.body);
    }
  }, [openNav]);

  const items = childrenById(1).filter((item) => item.showinnav === true);
  const logo = byTemplate("footer")[0];

  const renderSubnav = (page) => {
    const children = page.fieldgroup1;
    if (
      children &&
      children.length > 0
      // !APP_CONFIG.noSubnav.includes(template)
    ) {
      return (
        <div className={cl(["subnav", openSubNav && "active"])}>
          {children.map((item) => {
            const linkObj = parse(item.buttonlink1);
            return (
              <Link
                className={cl("subnav__item")}
                key={item.id}
                path={linkObj.link}
                onClick={() => {
                  ga("send", {
                    hitType: "Event",
                    eventCategory: "Click",
                    eventAction: "Completion",
                    eventLabel: "Booking",
                    eventValue: 0,
                  });
                }}
              >
                <div
                  className={cl("subnav__itemTitle")}
                  onMouseEnter={() =>
                    item.template === "reservations" && setOpenSubNav(true)
                  }
                  onMouseLeave={() =>
                    item.template === "reservations" && setOpenSubNav(false)
                  }
                >
                  {linkObj.text}
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
  };

  const renderHamburger = () => {
    return openNav ? (
      <button onClick={() => setOpenNav(false)} className={cl(["modal_close"])}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          className={cl(["svg"])}
        >
          <path d="m.35.35 21 21M.35 21.35l21-21" />
        </svg>
      </button>
    ) : (
      <div className={cl("hamburger")} onClick={() => setOpenNav(!openNav)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  };

  return (
    <nav className={cl("container")}>
      <div className={cl("logo_container")}>
        <Link path="/">
          <Image
            className={cl("logo")}
            src={logo.image1}
            alt={logo.image1_alt_text}
          />
        </Link>
      </div>
      {tablet ? (
        renderHamburger()
      ) : (
        <ul className={cl("button_container")}>
          {items &&
            items.map((item) => {
              if (item.template === "gallery") return null;
              return (
                <div
                  className={cl("nav_item_container")}
                  onMouseEnter={() =>
                    item.template === "reservations" && setOpenSubNav(true)
                  }
                  onMouseLeave={() =>
                    item.template === "reservations" && setOpenSubNav(false)
                  }
                >
                  <Link
                    key={item.id}
                    className={cl("button")}
                    onClick={() => {
                      setLocation(item.path)
                      window.history.replaceState( {} , "", item.path )
                    }}
                  >
                    {item.linktitle.toUpperCase()}
                  </Link>
                  {item.template === "reservations" &&
                    openSubNav &&
                    renderSubnav(item)}
                </div>
              );
            })}
        </ul>
      )}
      {tablet && openNav && (
        <MobileNav
          setOpenNav={setOpenNav}
          opennav={openNav}
          setLocation={setLocation}
        />
      )}
    </nav>
  );
}

export default Nav;
