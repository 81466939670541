import Nav from "./Nav";

Header.propTypes = {}
function Header(){
  return (
    <header className="header">
      <Nav />
    </header>
  );
}

export default Header;
