import Image from "../widgets/Image";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/views/contact.scss";
import { useAppState } from "../providers";

import {
  StandardInput,
  TextareaInput,
  SubmitInput,
  FormProvider,
  patterns,
  SelectInput,
  middleware,
} from "../form";

const cl = _classes(styles);

export default function Contact({ page }) {
  const { byTemplate } = useAppState();
  const restaurantsList = byTemplate("restaurant");
  const restaurantTitles = restaurantsList.map(
    (restaurant) => {
      return { value: restaurant.title, label: restaurant.title }
    }
  );

  const renderForm = () => {
    return (
      <div>
        <FormProvider
          onSubmit={"contact_us"}
          googleAnalytics={{
            gaLabel: "Contact",
          }}
        >
          <div className={cl("inquiries_container")}>
            <div dangerouslySetInnerHTML={{ __html: page.blurb1 }} />
            <div>
              General Inquiries: <a href={`mailto:${page.h2}`}>{page.h2}</a>
            </div>
            <div>
              Press Inquiries: <a href={`mailto:${page.h3}`}>{page.h3}</a>
            </div>
          </div>
          <div className={cl("subjectline")}>
            <SelectInput
              name="restaurant"
              label=""
              placeholder="Please select Restaurant."
              options={restaurantTitles}
              defaultValue=""
              rules={{ required: "Restaurant is required" }}
            />
          </div>
          <div className={cl("block1_wrapper")}>
            <StandardInput
              name="first_name"
              label=""
              placeholder="FIRST NAME"
              rules={{ required: "First name is required" }}
            />
            <StandardInput
              name="last_name"
              label=""
              placeholder="LAST NAME"
              rules={{ required: "Last name is required" }}
            />
          </div>
          <div className={cl("block2_wrapper")}>
            <StandardInput
              name="email"
              label=""
              placeholder="EMAIL"
              rules={{ required: "Email is required", pattern: patterns.email }}
            />

            <StandardInput
              name="phone"
              label=""
              placeholder="PHONE"
              middleware={middleware.phone}
            />
          </div>
          <div>
            <TextareaInput
              name="comments"
              label=""
              placeholder="MESSAGE"
              rules={{ required: "Message is required" }}
            />
          </div>
          <SubmitInput />
        </FormProvider>
      </div>
    );
  };

  const renderContactBlock = () => {
    return (
      <div className={cl(["contact_block_container"])}>
        <div className={cl("left_content")}>
          <h2 className={cl("title_container")}>
            <div>{page.h1}</div>
            <div className={cl("title_colored")}>{page.h4}</div>
            <div>{page.h5}</div>
          </h2>
          <div className={cl("form_container")}>{renderForm()}</div>
        </div>
        <div className={cl("image_container")}>
          <Image
            className={cl("image")}
            src={page.image1}
            alt={page.image1_alt_text}
          />
        </div>
      </div>
    );
  };
  return <div>{renderContactBlock()}</div>;
}
