import styles from "../../styles/views/team.scss";
import { _classes, parse } from "../utilities/helpers";
import Carousel from "../widgets/Carousel";
import Image from "../widgets/Image";
import { useViewportContext } from "../providers";
import CTA from "../widgets/CTA";
import Modal from "../widgets/Modal";
import PopUp from "../partials/TeamPopup.js";
import { useState } from "react";
const cl = _classes(styles);

Team.propTypes = {
  page: PropTypes.object,
};

export default function Team({ page }) {
  const members = page.fieldgroup1;
  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");
  const [modalPopup, setModalPopup] = useState(false);
  const [index, setIndex] = useState();

  const sliderSettings = () => {
    if (tablet) {
      return {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      };
    }

    return {
      infinite: true,
      slidesToShow: members.length,
      slidesToScroll: members.length,
      arrows: false,
    };
  };

  const renderCarousel = () => {
    return (
      <div className={cl("_")}>
        <Carousel contentTiles settings={sliderSettings()}>
          {members.length > 0 &&
            members?.map((block, i) => {
              return (
                <div className={cl("imgAndCaptions_wrapper")} key={block.id}>
                  <div className={cl("image_container")}>
                    <Image
                      className={cl("image")}
                      src={block.image1}
                      alt={block.image1_alt_text}
                      align={block.image1_anchor}
                    />
                  </div>
                  <div className={cl("caption_container")}>
                    <div>
                      <h3 className={cl("name_caption")}>{block.h2}</h3>
                      <h3 className={cl("position_caption")}>{block.h3}</h3>
                    </div>
                    <div className={cl("cta_container")}>
                      <CTA
                        className={cl("button")}
                        text={parse(block.buttonlink1).text}
                        link={""}
                        onClick={() => {
                          setIndex(i);
                          modalPopup
                            ? setModalPopup(false)
                            : setModalPopup(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </Carousel>
      </div>
    );
  };

  return (
    <div className={cl(["team_block_container", !tablet && "tablet"])}>
      <div className={cl("title_container")}>
        <h2 className={cl("title_wrapper")}>
          <div className={cl("title")}>{page.h1}</div>
        </h2>
      </div>
      {members && members.length > 0 && renderCarousel()}
      <Modal visible={modalPopup}>
        <PopUp
          fieldgroup1index={index}
          close={() =>
            modalPopup ? setModalPopup(false) : setModalPopup(true)
          }
        />
      </Modal>
    </div>
  );
}
