import useAppState from "../providers/AppProvider";
import Link from "../widgets/Link";
import styles from "../../styles/partials/footer.scss";
import { _classes, parse } from "../utilities/helpers";
import Image from "../widgets/Image";
import { useState } from "react";

const cl = _classes(styles);

Footer.propTypes = {};

function Footer() {
  const { byTemplate } = useAppState();
  const [credit, setCredit] = useState("CREDITS")
  const page = byTemplate("footer")[0];
  const socialIcons = page.fieldgroup3;
  const addressObj = page.buttonlink1 && parse(page.buttonlink1);

  return (
    <footer className={cl("footer_container")}>
    
      <div className={cl("left_side_content")}>
        <Link
          path={addressObj.link}
          className={cl("el_1")}
        >
          {addressObj.text}
        </Link>
        <Link
          path={`mailto:${page.h2}`}
          className={cl("el_2")}
        >
          {page.h2}
        </Link>
        <Link
          path={`tel:${page.h3}`}
          className={cl("el_3")}
        >
          {page.h3}
        </Link>
      </div>

      <div className={cl("right_side_content_container")}>
        {page.fieldgroup1 && page.fieldgroup1.length > 0 && (
          <ul className={cl("right_side_links_container")}>
            {page.fieldgroup1.map((item) => {
              return (
                <li key={item.id}>
                  <Link path={item.h2}>{item.h1}</Link>
                </li>
              );
            })}
            <li>
              <Link
                path={"https://www.onceuponatime.agency/hospitality"}
              >
                <span
                  className={cl("credits")}
                  onMouseEnter={() => setCredit("ONCE UPON A TIME")}
                  onMouseLeave={() => setCredit("CREDITS")}
                >{credit}</span>
              </Link>
            </li>
          </ul>
        )}

        {socialIcons && socialIcons.length > 0 && (
          <div className={cl("social_icons_container")}>
            {socialIcons.map((item, el) => {
              return (
                <Link path={page[`social_icon${el+1}`]}>
                  <Image
                    className={cl(`${item.h1}`)}
                    src={item.image1} 
                    alt={item.image1_alt_text} 
                  />
                </Link>
              );
            })}
          </div>
        )}
      </div>

    </footer>
  );
}

export default Footer;
