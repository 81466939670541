import Link from "./Link";
import styles from "../../styles/widgets/_cta.scss";

CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CTA.defaultProps = {
  link: "",
  text: "",
  onClick: () => null,
  className: "",
};

function CTA({ link, text, onClick, className }) {
  return (
    <Link
      className={`${className} ${styles.cta}`}
      path={link}
      ariaLabel={text}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </Link>
  );
}

export default CTA;
