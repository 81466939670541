// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home______3Q8VN{overflow-x:hidden}", "",{"version":3,"sources":["webpack://./src/styles/views/home.scss"],"names":[],"mappings":"AAAA,iBAGE,iBAAA","sourcesContent":["._ {\n  // background: pink;\n  // font-family: \"Kepler Std\";\n  overflow-x: hidden;\n}\n\n// .home-carousel-wrapper {\n//   margin: 0;\n//   position: relative;\n//   height: 700px;\n\n//   img {\n//     height: 100%;\n//     // max-height: 700px;\n//     width: 100%;\n//     position: relative;\n//   }\n\n//   @media screen and (max-width: 999px) {\n//     // height: 56vw;\n//     height: auto;\n\n//     img {\n//       margin: 0 auto;\n//       height: auto;\n//       max-height: 550px;\n//       max-width: 100%;\n//     }\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "home______3Q8VN"
};
export default ___CSS_LOADER_EXPORT___;
