import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import Home from "../views/home";

Routes.propTypes = {};

export default function Routes() {
  const { content, location } = useAppState();

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const baseRoutes = createBaseRoutes();
    setRoutes(baseRoutes);
  }, []);
  const createBaseRoutes = () => {
    const donotroute = ["footer"];
    return Object.keys(content)
    .map((key) => {
      const page = content[key];
      if (Object.keys(page).length === 0) {
        console.log("%c MATCH", "color:red");
        return (
          <Route
            path="/"
            key="redirectAllElse"
            render={() => <Redirect to="/" />}
          />
        );
      }
      return (
        <Route
          key={page.path}
          path={page.path}
          render={(props) => (
            <LazyBoundary fallback={<div />}>
              <ErrorBoundary>
                <div {...props} location={page.path} />
              </ErrorBoundary>
            </LazyBoundary>
          )}
        />
      )
    })
    .concat(
      <Route
        path="/"
        key="home"
        render={(props) => (
          <LazyBoundary fallback={<div />}>
            <ErrorBoundary>
              <Home {...props} location={location} />
            </ErrorBoundary>
          </LazyBoundary>
        )}
      />
    )
    .concat(
      <Route
        path="/"
        key="redirectAllElse"
        render={() => <Redirect to="/" />}
      />
    );
  }

  return (
    <main>
      {routes}
    </main>
  );
}
