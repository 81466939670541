import { Waypoint } from "react-waypoint";
import zenscroll from "zenscroll";

class ScrollContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    path: PropTypes.string,
    history: PropTypes.object,
    scrollLocation: PropTypes.string,
    animateSection: PropTypes.func,
    section: PropTypes.string,
    sectionsAnimated: PropTypes.object,
    resetScrollLocation: PropTypes.func,
    homePath: PropTypes.string,
    topOffSet: PropTypes.number,
    page: PropTypes.object,
    aboutpaths: PropTypes.array,
    className: PropTypes.string,
    addBorder: PropTypes.bool,
    addSpace: PropTypes.bool,
  };
  static defaultProps = {
    topOffSet: 20,
    isProject: false,
    addSpace: false,
  };
  constructor() {
    super();
    this.mobile = window.innerWidth < 769;
  }

  componentDidMount() {
    let top = this.props.topOffSet;

    if (window.innerWidth < 769) {
      top = 60;
    }
    const off_top = this.mobile ? 40 : 90;
    zenscroll.setup(850, top);
    if (window.location.pathname === this.props.path) {
      zenscroll.toY(zenscroll.getTopOf(this.section));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.scrollLocation !== this.props.scrollLocation &&
      this.props.scrollLocation === this.props.path &&
      !zenscroll.moving()
    ) {
      this.scrollToSection();
    }
  };

  scrollToSection = () => {
    // const { page } = this.props;
    // const _pathname = window.location.pathname;
    let offsetting = this.mobile ? 60 : 80;

    zenscroll.toY(zenscroll.getTopOf(this.section) - offsetting);
  };

  handleEnter = () => {
    if (
      window.location.pathname !== this.props.path &&
      !zenscroll.moving() &&
      window.location.pathname !== "/legal"
    ) {
      this.props.resetScrollLocation();
      window.history.replaceState( {} , "", this.props.path )
      // this.props.history.push(this.props.path);
    }
  };

  render() {
    const { addBorder, className, addSpace } = this.props;
    return (
      <div
        className={`scroll-container ${
          addBorder ? ` border__top` : ""
        } ${className} ${addSpace && addSpace ? ` spacing` : ""}`}
      >
        <div className="waypoint-setter">
          <Waypoint
            onEnter={this.handleEnter}
            fireOnRapidScroll={false}
            scrollableAncestor={window}
            bottomOffset={"40%"}
          />
        </div>
        <div ref={(el) => (this.section = el)}>{this.props.children}</div>
      </div>
    );
  }
}

export default ScrollContainer;
