import styles from "../../styles/partials/_cookie-popup.scss";
import { useState } from "react";
import useAppState from "../providers/AppProvider";

CookiePopup.propTypes = {};

function CookiePopup() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];

  const [visible, setVisibility] = useState(true);

  const toggleVisible = () => {
    setVisibility(false);
    window.localStorage.setItem("cookiePopup", true);
  };

  if (!window.localStorage.cookiePopup) {
    return (
      <div
        className={`${styles.cookiePopup} ${
          visible ? styles.cookiePopup_visible : ""
        }`}
      >
        <div className={styles.cookiePopup__inner}>
          <div
            className={`${styles.cookiePopup__blurb} global-blurb light-blurb`}
            dangerouslySetInnerHTML={{ __html: page.blurb3 }}
          />
          <button
            className={styles.cookiePopup__button}
            onClick={toggleVisible}
          >
            Got it
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default CookiePopup;