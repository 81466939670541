import { useEffect, useRef } from "react";
import SlickSlider from "react-slick";
import Image from "widgets/Image";
import leftArrow from "../../images/leftArrow.png";
import rightArrow from "../../images/rightArrow.png";
import leftArrow_dark from "../../images/leftArrow_dark.png";
import rightArrow_dark from "../../images/rightArrow_dark.png";
import { useViewportContext } from "../providers";

const Carousel = (props) => {
  const {
    keyboardControl,
    settings,
    slides,
    darkArrows,
    heroBannerCarousel,
    contentTiles,
  } = props;
  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");

  const slick = useRef(null);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const imageComponentFor = (slide) => {
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.image1,
      alt: slide.image1_alt_text,
    };

    return <Image {...imgProps} />;
  };

  const handleKeyDown = (e) => {
    if (keyboardControl && slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick.slickNext();
        }
      }
    }
  };

  if (slides && slides.length === 1 && !contentTiles) {
    return (
      <div
        className={`carousel-with-one-image ${settings.className}`}
        style={{ padding: 0 }}
      >
        {imageComponentFor(slides[0])}
      </div>
    );
  }

  if (heroBannerCarousel && !contentTiles && slides) {
    return (
      <SlickSlider ref={slick} {...settings}>
        {slides.map((slide, i) => {
          return (
            <div className="carousel-image-container" key={i}>
              {imageComponentFor(slide)}
            </div>
          );
        })}
      </SlickSlider>
    );
  }

  const contentCarousel = () => (
    <>
      <SlickSlider ref={slick} {...settings}>
        {props.children}
      </SlickSlider>
      <button
        className={`tilesCarousel carousel-arrow arrow-left ${
          darkArrows && "dark-arrow-left"
        }`}
        style={{
          backgroundImage: `url(${darkArrows ? leftArrow_dark : leftArrow})`,
        }}
        onClick={() => slick?.current?.slickPrev()}
      />
      <button
        className={` tilesCarousel carousel-arrow arrow-right ${
          darkArrows && "dark-arrow-right"
        }`}
        style={{
          backgroundImage: `url(${darkArrows ? rightArrow_dark : rightArrow})`,
        }}
        onClick={() => slick?.current?.slickNext()}
      />
    </>
  );

  const imageCarousel = () => (
    <>
      <SlickSlider ref={slick} {...settings}>
        {slides.map((slide, i) => {
          return tablet ? (
            <div className="carousel-image-container" key={i}>
              {imageComponentFor(slide)}
            </div>
          ) : (
            <div>
              <img
                key={i}
                src={slide.image1}
                alt={slide.image1_alt_text}
                role="presentation"
              />
            </div>
          );
        })}
      </SlickSlider>
      <button
        className={`carousel-arrow arrow-left ${
          darkArrows && "dark-arrow-left"
        }`}
        style={{
          backgroundImage: `url(${darkArrows ? leftArrow_dark : leftArrow})`,
        }}
        onClick={() => slick?.current?.slickPrev()}
      />
      <button
        className={`carousel-arrow arrow-right ${
          darkArrows && "dark-arrow-right"
        }`}
        style={{
          backgroundImage: `url(${darkArrows ? rightArrow_dark : rightArrow})`,
        }}
        onClick={() => slick?.current?.slickNext()}
      />
    </>
  );

  if (contentTiles) {
    return contentCarousel();
  }

  return imageCarousel();
};

export default Carousel;

Carousel.propTypes = {
  slides: PropTypes.array,
  settings: PropTypes.object,
  className: PropTypes.string,
  keyboardControl: PropTypes.bool,
  imageClass: PropTypes.string,
  contentcarousel: PropTypes.boolean,
};

Carousel.defaultProps = {
  settings: {
    arrows: false,
    className: "center",
    dots: false,
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  },
};
