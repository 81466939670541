import React from "react";
import Carousel from "../widgets/Carousel";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/views/special_events.scss";
import CTA from "../widgets/CTA";
import { useViewportContext } from "../providers";
import { parse } from "../utilities/helpers";
import Modal from "../widgets/Modal";
import PopUp from "../partials/Popup";
import { useState } from "react";

const cl = _classes(styles);

const SpecialEvents = ({ page }) => {
  const slides = page.fieldgroup1;

  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");

  const sliderSettings = () => {
    if (tablet) {
      return {
        arrows: false,
        centerMode: false,
        className: "special-events-carousel center",
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,
      };
    }

    return {
      arrows: false,
      centerMode: false,
      className: "special-events-carousel center",
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: true,
    };
  };

  const [eventPopup, setEventPopup] = useState(false);
  const buttonlink1obj =
    page.fieldgroup2 &&
    page.fieldgroup2[0].buttonlink1 &&
    parse(page.fieldgroup2[0].buttonlink1);
  const buttonlink2obj =
    page.fieldgroup2 &&
    page.fieldgroup2[0].buttonlink2 &&
    parse(page.fieldgroup2[0].buttonlink2);

  if (!page.fieldgroup2) return "";

  return (
    <div className={cl("special_events_container")}>
      {slides && slides.length > 0 && (
        <div className={cl("special-events-carousel-wrapper")}>
          <Carousel slides={slides} settings={sliderSettings()} />
        </div>
      )}
      <div className={cl("content_block_container")}>
        <h2 className={cl("title")}>
          <div className={cl("title_grey")}>{page.fieldgroup2[0].h1}</div>
          <div className={cl("title_colored")}>{page.fieldgroup2[0].h2}</div>
        </h2>
        <div className={cl("paragraph_and_CTA")}>
          <div
            dangerouslySetInnerHTML={{
              __html: page.fieldgroup2[0].blurb1,
            }}
            className={cl("paragraph")}
          />
          <div className={cl("cta_container")}>
            {buttonlink1obj && buttonlink1obj.link !== "" && (
              <div className={cl("cta_wrapper_left")}>
                <CTA
                  className={cl("cta_left")}
                  text={buttonlink1obj.text}
                  link={""}
                  onClick={() => {
                    eventPopup ? setEventPopup(false) : setEventPopup(true);
                  }}
                />
              </div>
            )}
            {buttonlink2obj && buttonlink2obj.link !== "" && (
              <div className={cl("cta_wrapper_right")}>
                <CTA
                  className={cl("cta_right")}
                  text={buttonlink2obj.text}
                  link={buttonlink2obj.link}
                />
              </div>
            )}
            <Modal visible={eventPopup}>
              <PopUp
                close={() =>
                  eventPopup ? setEventPopup(false) : setEventPopup(true)
                }
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialEvents;
