import Image from "../widgets/Image";
import Carousel from "../widgets/Carousel";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/views/restaurants.scss";
import CTA from "../widgets/CTA";
import { parse } from "./../utilities/helpers";
import { useViewportContext, useAppState } from "../providers";

Restaurants.propTypes = {
  page: PropTypes.object,
};

const cl = _classes(styles);

export default function Restaurants({ page }) {
  const { viewport } = useViewportContext();
  const tablet = viewport.is("tablet");
  const { byTemplate } = useAppState();
  const restaurantsList = byTemplate("restaurant");

  const sliderSettings = () => {
    if (tablet) {
      return {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      };
    }

    return {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
    };
  };

  const renderCarousel = () => {
    if (restaurantsList) {
      return (
        <div className={cl("restaurant-carousel-wrapper")}>
          <Carousel contentTiles settings={sliderSettings()}>
            {restaurantsList &&
              restaurantsList.map((block, index) => {
                if (!tablet) {
                  return (
                    <div key={index} className={cl("imgAndCaptions_wrapper")}>
                      <div className={cl("image_container")}>
                        <Image
                          className={cl("image")}
                          src={block.image1}
                          alt={block.image1_alt_text}
                          align={block.image1_anchor}
                        />
                        <div className={cl("hover_overlay")}>
                          {block.h3 !== "" && <p>{block.h3}</p>}
                          {block.buttonlink1 &&
                            block.buttonlink1.length !== 0 && (
                              <CTA
                                className={cl("overlay_cta")}
                                link={parse(block.buttonlink1).link}
                                text={parse(block.buttonlink1).text}
                              />
                            )}
                        </div>
                      </div>
                      <div className={cl("caption_container")}>
                        <h3 className={cl("name_caption")}>{block.h1}</h3>
                        <div className={cl("location_caption")}>{block.h2}</div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} className={cl("imgAndCaptions_wrapper")}>
                    <div className={cl("image_container")}>
                      <Image
                        className={cl("image")}
                        src={block.image1}
                        alt={block.image1_alt_text}
                        align={block.image1_anchor}
                      />
                    </div>
                    <div className={cl("caption_container")}>
                      <h3 className={cl("name_caption")}>{block.h1}</h3>
                      <div className={cl("location_caption")}>{block.h2}</div>
                      {block.buttonlink1 && block.buttonlink1.length !== 0 && (
                        <CTA
                          className={cl("overlay_cta")}
                          link={parse(block.buttonlink1).link}
                          text={parse(block.buttonlink1).text}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      );
    }
  };

  return (
    <div className={cl("restaurant_block_container")}>
      <div className={cl("title_container")}>
        <h2 className={cl("title_wrapper")}>
          <div className={cl("title")}>{page.h1}</div>
          <div className={cl("title_colored")}>{page.h2}</div>
        </h2>
      </div>
      {restaurantsList && restaurantsList.length != 0 && renderCarousel()}
    </div>
  );
}
